import React from 'react';

import { Layout } from '@app/components';

const NotFound: React.FC = () => (
  <Layout>
    <div className="container">
      <div className="flex flex-col text-center pb-24">
        <h2 className="text-[260px] font-bold">
          <span>404</span>
        </h2>
        <h3 className="text-3xl text-gray-500">The page cannot be found.</h3>
      </div>
    </div>
  </Layout>
);

export default NotFound;
